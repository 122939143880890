import {NotiMessageDto} from "../../types/notification";
import dayjs from "dayjs";
import Logo from '../../assets/img/logo_carhartt.png';

type NotificationListItemProps = {
    notification: NotiMessageDto;
    onclick: () => void;
}

const NotificationListItem = (props: NotificationListItemProps) => {
    const notification = props.notification;

    return (
        <div className={`py-5 px-4 bg-white ${!!notification.pinnedStartDate && 'border-t-2 border-black'} ${!!notification.readTs && 'opacity-50 text-gray-500'}`} onClick={props.onclick}>
            <div className={`flex space-x-4`}>
                <div>
                    <img src={notification.imageUrl || Logo} alt={'notice_icon'} className={'w-11 h-11'}/>
                </div>
                <div className={'flex-1 space-y-1'}>
                    <div className={'text-12 font-bold'}>{notification.title}</div>
                    <div className={'text-12 font-normal'}>{notification.description}</div>
                    <div className={'text-12 text-gray-500'}>
                        {dayjs(notification.displayStartDate).format('YYYY-MM-DD')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationListItem;

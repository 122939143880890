import {
  default as ReactPixel,
  AdvancedMatching,
  Options,
} from 'react-facebook-pixel';
import debug from '../utils/logging.js';

const log = (name: string, ...args: any[]) => debug.log('[Facebook Pixel]', `(${name})`, "\t", ...args);

const {
  init,
  pageView,
  track,
} = ReactPixel;

const Pixel = {
  loaded: () => {
    debug.log("\n");
    log('module loaded');
  },
  init: (pixelId: string, advancedMatching?: AdvancedMatching, options?: Options): void => {
    log('init', "\t", 'pixelId', pixelId);
    init(pixelId, advancedMatching, options);
  },
  pageView: (pathname?: string) => {
    log('pageView', pathname);
    pageView();
  },
  viewContent: (productId: number, productName: string, value: number) => {
    const param = {
      content_ids: [productId], 
      content_name: productName, 
      content_type: 'product',
      value: value,
      currency: 'KRW'
    };
    log('ViewContent', param);
    track('ViewContent', param);
  },
  addToCart: (productId: number, productName: string, value: number) => {
    const param = {
      content_ids: [productId], 
      content_type: 'product',
      content_name: productName,
      value: value,
      currency: 'KRW'
    };    
    log('AddToCart', param);
    track('AddToCart', param);
  },
  addToWishlist: (productId: number, productName: string, value: number) => {
    const param = {
      content_ids: [productId], 
      content_name: productName, 
      content_type: 'product',
      value: value,
      currency: 'KRW'
    };
    log('AddToWishLlist', param);
    track('AddToWishlist', param);
  },
  purchase: (productIds: number[], value: number) => {
    const param = {
      content_ids: productIds,
      content_type: 'product',
      value: value,
      currency: 'KRW'
    };
    log('Purchase', param);
    track('Purchase', param);
  },
  search: (keyword: string) => {
    const param = { search_string: keyword };
    log('Search', param);
    track('Search', param);
  },

  // 등록 관련 (등록 == 가입)
  completeRegistration: (data?: object) => { // 등록양식 작성 완료 이벤트
    log('CompleteRegistration', data);
    track('CompleteRegistration', data);
  },
  lead: (data?: object) => { // 등록완료 이벤트
    log('Lead', "\t", data);
    track('Lead', data);
  }
}

export default Pixel;

import {useHistory} from "react-router-dom";
import {Button} from "../components/Button";

const ServerError = () => {
    const history = useHistory();

    return (
        <div className={'flex w-full h-screen bg-center bg-no-repeat bg-cover'}
             style={{backgroundImage: 'url("https://s3.ap-northeast-2.amazonaws.com/media.worksout.co.kr/uploads/live/rl2cd31gpd2c.jpg")'}}
        >
            <div className={'flex w-full h-screen'}
                 style={{backgroundColor: 'rgba(0,0,0,0.3)'}}
            >
                <div className={'m-auto text-center text-white p-6 pb-40'}>
                    <h2>죄송합니다</h2>
                    <p className={'py-4'}>현재 서비스가 잠시 지연되고 있습니다. <br/>잠시 후 다시 시도해주세요.</p>
                    <Button className={'button bg-white text-black w-50 h-12'} onClick={() => history.push('/')}>
                        칼하트 온라인 스토어로 이동
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ServerError;
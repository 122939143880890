import React, { useEffect, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Router } from './pages/Router';
import './ReactToastify_custom.css';
import appboy from '@braze/web-sdk';
import airbridge from 'airbridge-web-sdk-loader';
import { CookiesProvider } from 'react-cookie';
import {
  UserProvider,
  useUserDispatch,
  useUserState,
} from './context/UserContext';
import { getUserInfo } from './apis/user';
import ServerError from './pages/ServerError';
import { Action, Bridge } from './plugins/bridge';
import { isMobile } from 'react-device-detect';
import Loading from "./components/Spinner";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollRestoration from './common/ScrollRestoration';
import { getAmplitudeInstance } from './plugins/amplitude';


airbridge.init({
  app: process.env.REACT_APP_AIRBRIDGE_APP_NAME ?? '',
  webToken: process.env.REACT_APP_AIRBRIDGE_TOKEN ?? '',
  shareCookieSubdomain: false,
  utmParsing: true,
  user: {
    alias: {
      amplitude_device_id: getAmplitudeInstance('carhartt').getDeviceId(),
    },
  },
});
airbridge.setDeeplinks({
  buttonID: 'app_download',
  deeplinks: {
    ios: 'carhartt://',
    android: 'carhartt://',
  },
  fallbacks: {
    ios: 'itunes-appstore',
    android: 'google-play',
  },
});

appboy.initialize(process.env.REACT_APP_BRAZE_API_KEY ?? '', {
  baseUrl: process.env.REACT_APP_BRAZE_SDK_ENDPOINT ?? '',
  enableHtmlInAppMessages: true,
  allowUserSuppliedJavascript: true,
});
// appboy 로그 토글
// appboy.toggleAppboyLogging();
appboy.display.automaticallyShowNewInAppMessages();
appboy.openSession();

const channel = require('./plugins/channel').default;
!window.ChannelIO && channel.loadScript();

// @ts-ignore
const { IMP } = window;
IMP.init(process.env.REACT_APP_IAMPORT_KEY);

const CarharttRoute = () => {
  const { isLoggedIn, userInfo } = useUserState();
  const dispatch = useUserDispatch();

  useEffect(() => {
    Bridge.postMessage(Action.getToken, null);
    getUserInfo()
      .then(r => {
        dispatch({ type: 'LOGIN', payload: r.data.payload });
      })
      .catch(e => {
        dispatch({ type: 'LOGOUT' });
      });
    Bridge.sendBraze('getDeviceId');
  }, []);

  useEffect(() => {
    AOS.init();
  })
  const _checkUser = async () => {
    if (isLoggedIn && userInfo) {
      if (
        isMobile &&
        navigator.userAgent.indexOf('!!!IN APP EVENT!!!') !== -1
      ) {
        Bridge.sendBraze('changeUser', String(userInfo?.userId));
        Bridge.sendAmplitude('setUserId', String(userInfo?.userId));
        Bridge.sendAirbridge('setUserId', undefined, String(userInfo?.userId));
      } else {
        appboy.changeUser(String(userInfo.userId));
        getAmplitudeInstance('carhartt').setUserId(String(userInfo.userId));
        airbridge.setUserId(String(userInfo.userId));
      }
    }
  };

  const channelBoot = async () => {
    if (isLoggedIn && typeof userInfo?.userId !== 'undefined') {
      channel.boot({
        "pluginKey": process.env.REACT_APP_CHANNEL_TALK_KEY,
        "memberId": userInfo?.userId,
        "profile": {
          "name": userInfo?.userName,
          "mobileNumber": userInfo?.userPhone,
          "email": userInfo?.userEmail
        },
        "mobileMessengerMode": "iframe"
      });
    } else {
      channel.boot({
        "pluginKey": process.env.REACT_APP_CHANNEL_TALK_KEY,
        "mobileMessengerMode": "iframe"
      });
    }
  }

  useEffect(() => {
    _checkUser();
    channelBoot();
  }, [userInfo]);

  return (
    <CookiesProvider>
      <BrowserRouter>
        <ScrollRestoration />
        <Suspense fallback={
          <Loading/>
        }>
          <Switch>
            <Route path="/307" component={ServerError} />
            <Route path="/" component={Router} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </CookiesProvider>
  );
};

function App() {
  return (
    <UserProvider>
      <CarharttRoute />
    </UserProvider>
  );
}

export default App;

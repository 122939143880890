import * as amplitude from '@amplitude/analytics-browser';
import { AutocaptureOptions } from '@amplitude/analytics-types';
import { getEnvKey } from '../utils/getEnvKey';

export type AmplitudeInstance = {
  [key: string]: amplitude.Types.BrowserClient
}
type AmplitudeInitOptions = {
  [key: string]: {
    apiKey: string,
    config: {
      instanceName?: string,
      autocapture: AutocaptureOptions,
      minIdLength: number,
    },
  }
}

const amplitudeInitOptions: AmplitudeInitOptions = {
  worksout: {
    apiKey: getEnvKey(process.env.REACT_APP_AMPLITUDE_API_KEY_WORKSOUT,'REACT_APP_AMPLITUDE_API_KEY_WORKSOUT'),
    config: {
      instanceName: 'worksout',
      autocapture: {
        sessions: true,
        attribution: false,
        formInteractions: false,
        fileDownloads: false,
        pageViews: false,
        elementInteractions: false,
      },
      minIdLength: 1,
    },
  },
  carhartt: {
    apiKey: getEnvKey(process.env.REACT_APP_AMPLITUDE_API_KEY,'REACT_APP_AMPLITUDE_API_KEY'),
    config: {
      autocapture: {
        sessions: true,
        attribution: true,
        formInteractions: false,
        fileDownloads: false,
        pageViews: false,
        elementInteractions: false,
      },
      minIdLength: 1,
    },
  },
};
const amplitudeInstance: AmplitudeInstance = {};

type ProjectName = 'worksout' | 'carhartt';
export const getAmplitudeInstance = (projectName: ProjectName): amplitude.Types.BrowserClient => {
  if (!amplitudeInstance[projectName]) {
    const amp = amplitude.createInstance();
    amp.init(amplitudeInitOptions[projectName].apiKey as string, amplitudeInitOptions[projectName].config);
    amplitudeInstance[projectName] = amp;
  }
  return amplitudeInstance[projectName];
};

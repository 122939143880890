import {ToastOptions} from "react-toastify/dist/types";
import {toast} from "react-toastify";

export const reactToast: ToastOptions = {
    position: "top-center",
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
};

export const nToast = (text: string) => toast(text, reactToast)

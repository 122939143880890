import { api } from '../plugins/axios';
import {
  BookmarkRequest,
  BookmarkResponse,
  CartAddRequest,
  CartResponse,
  Paged,
  ProductDetail,
  ProductListSearchOption,
  ProductQuery,
  ProductSize,
  RelatedProducts,
  TResponse,
  TCartItem,
  ProductSizeByCategoryDto, ProductSizeFilterDTO,
} from '../types/types';
import debug from '../utils/logging.js';

export const getProductList = (searchOption?: ProductListSearchOption) => {
  const url = `/v1/products`;
  const params = { ...searchOption, brandIds: 15 };
  return api.get<TResponse<Paged<ProductQuery>>>(url, { params, cache: true });
};

export const getProductDetail = (productId: number) => {
  const url = `/v1/products/${productId}/detail`;
  return api.get<TResponse<ProductDetail>>(url);
};

export const getRelatedProducts = (productId: number) => {
  const url = `/v1/products/${productId}/fittingcuts`;
  return api.get<TResponse<ProductQuery[]>>(url);
};

export const getProductCustomList = (productIds: number[]) => {
  return api.get<TResponse<ProductQuery[]>>(`/v1/products/${productIds}`);
};

export const getProductSizes = (productId: number) => {
  return api.get<TResponse<ProductSize[]>>(`/v1/products/${productId}/sizes`);
};

export const getBulkProductSizes = (productIds: number[]) => {
  return api.post<TResponse<ProductSize[]>>(`/v1/products/sizes`, { productIds });
};

export const postBookmark = (data: BookmarkRequest) => {
  return api.post('/v1/bookmarks', data);
};

export const getBookmarks = () => {
  const params = { type: 'PRODUCT' };
  return api
    .get<TResponse<BookmarkResponse[]>>('/v1/bookmarks', { params })
    .catch(e => debug.log('getBookmarks e :', e));
};

export const getCarts = () => {
  return api.get<TResponse<CartResponse>>('/v1/carts');
};

export const addCartItem = (productId: number, data: CartAddRequest) => {
  return api.post(`/v1/carts/${productId}`, data);
};

export const removeCartItem = (productId: number, sizeId: number) => {
  const data = { sizeId };
  return api.put(`/v1/carts/${productId}`, data);
};

export const clearCart = () => {
  return api.post('/v1/carts/clear');
};

export const getCategorySizes = (
  mainCategoryId: number,
  subcategoryId?: number,
) => {
  const url = `/v1/categories/${mainCategoryId}/sizes`;
  const params = { subcategoryId };
  return api.get<TResponse<ProductSizeByCategoryDto[]>>(url, { params });
};

export const countUp = (targetId: number, targetType: string) => {
  return api.post<void>('/v1/view/countUp', { targetId, targetType });
};

export const getProductSizeFilter = (mainCategoryId?: number, subcategoryId?: number, secondCategoryId?: number) => {
  const url = `/v1/products/filter`;
  const params = { mainCategoryId, subcategoryId, secondCategoryId, brandId: 15, filterShow: true };
  return api.get<TResponse<ProductSizeFilterDTO>>(url, { params });
};
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './plugins/bridge';
import App from './App';
import './index.css';
import './sik.css';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import netfunnel from './plugins/netfunnel';
export const NetFunnel:any  = netfunnel;
const client = new QueryClient();
ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <HelmetProvider>
        <App />
        <ReactQueryDevtools initialIsOpen={false}/>
      </HelmetProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();

import {api} from "../plugins/axios";
import {TResponse, TCategory, TSubCategory} from "../types/types";

export const getCategories = () => {
    const params = {sourceSite: 'CARHARTT'}
    return api.get<TResponse<TCategory[]>>(`/v1/categories`, {params})
}

export const getSubCategories = (categoryId: number) => {
    return api.get<TResponse<TSubCategory[]>>(`/v1/categories/${categoryId}`)
}

export const get2ndCategories = (categoryId: number, categoryCodes: string[]) => {
    const params = {categoryCodes}
    return api.get<TResponse<TSubCategory[]>>(`/v1/categories/${categoryId}/second`, {params})
}

import { FC } from 'react';
import { Route } from 'react-router';

export interface AuthRouteProps {
  path: string | string[];
  component: any;
  redirectTo?: string;
  guestOnly?: boolean;
  guestOnlyRedirectTo?: string;
}

export const AuthRoute: FC<AuthRouteProps> = ({
  path,
  component: Component,
}) => {
  return (
    <Route
      path={path}
      render={() => {
        return <Component />;
      }}
    />
  );
};

export type PayMethod = 'CARD' | 'VIRTUAL_ACCOUNT' | 'CASH' | 'TRANSFER' | 'KAKAO' | 'NAVER';

export type TResponse<T> = {
    payload: T
    code: string
    message: string
}

export type Paged<T> = {
    content: T[]
    totalElements: number
    totalPages: number
}

export type CarharttBanner = {
    buttonLink1?: string
    buttonLink2?: string
    buttonText1?: string
    buttonText2?: string
    createdAt: string
    id: number
    imageUrl: string
    mobileImageUrl: string
    sort: number
    title: string
    visible: boolean
}

export type Content = {
    buttonLink?: string
    textColor?: string
    category?: string
    createdAt: string
    id: number
    rawImageUrl?: string
    sort: number
    thumbnailUrl?: string
    title: string
}

export type ContentDetail = {
    backgroundColor?: string
    contentId: number
    contentMedia: {
        id?: number
        rawUrl?: string
        thumbnailUrl?: string
        thumbnailed: boolean
        type: string
    }
    contents?: string
    createdAt: string
    description?: string
    textColor?: string
    title?: string
    titleUrl?: string
    updatedAt?: string
}

export type SignupRequest = {
    verificationNiceId: number
    agreements: string[]
    email: string
    password: string
    platform: 'ANDROID' | 'IPHONE' | 'WEB'
    source: 'CARHARTT'
}

export type Agreements = {
    agreements: Agreement[]
}

export type Agreement = {
    contents: string
    createdAt: string
    id: number
    type: string
    version?: number
}

export type LoginRequest = {
    email: string
    password: string
}

export type UserInfo = {
    ownPoint: number
    userCode: string
    userEmail: string
    userGender?: 'ETC' | 'FEMALE' | 'MALE'
    userGrade: string
    userId: number
    userName: string
    userPhone: string
    userBirth?: string
    agreements: string[]
    isVerified: boolean
}

export type Birth = {
    year: string
    month: string
    day: string
}

export enum AgreementType {
    PAYMENT = 'PAYMENT',
    PRIVACY = 'PRIVACY',
    SERVICE = 'SERVICE',
    THIRD = 'THIRD',
    MAILING = 'MAILING',
    SMS = 'SMS',
    MARKETING = 'MARKETING',
    AGE = 'AGE'
}

export enum TargetType {
    CONTENT = 'CONTENT',
    PRODUCT = 'PRODUCT',
    SEARCH = 'SEARCH'
}


export enum ScoreType {
    VIEW= 'VIEW',
    SEARCH = 'SEARCH',
    LIKE= 'LIKE'
}

export enum VerityType {
    BASIC = 'BASIC',
    PASSWORD = 'PASSWORD',
    SIGNUP = 'SIGNUP'
}


export type ProductDetail = {
    availableFrom: string
    availableStockUser: number
    brandName: string
    currentPrice: number
    detailContents: string
    discountedRate: number
    fittingHeight: number
    fittingSize: string
    fittingWeight: number
    images: ProductImageDto[]
    info: string
    initialPrice: number
    isMidsale: boolean
    isNewArrival: boolean
    isOnlyForUser: boolean
    isBlackFriday: boolean
    onlyOffline: boolean
    productCode: string
    productId: number
    productImageUrls: string[]
    productInfo: ProductInfo
    productKoreanName: string
    productName: string
    productSecondName?: string
    productSizes: ProductSize[]
    genderCode: string
    categoryCode: string
    categoryName: string | null
    isOnlyOne: boolean
    unavailableMileage: boolean
    unavailableCoupon: boolean
}

export type ProductImageDto = {
    originalUrl?: string
    sort: number
    thumbnailUrl?: string
    type: 'DETAIL' | 'FITTING' | 'HOVER' | 'SCHEME' | 'THUMBNAIL'
}

export type ProductInfo = {
    color: string
    colorKoreanName: string
    importer: string
    manufactureCountry: string
    manufacturer: string
    material: string
    productInfoId: number
    productionDate: string
}

export type ProductQuery = {
    brandName: string
    color: string
    colorKoreanName: string
    currentPrice: number
    discountedRate: number
    hoverUrl: string
    initialPrice: number
    isMidsale: boolean
    isBlackFriday: boolean
    isNewArrival: boolean
    isOnlyForUser: boolean
    isOnlyApp:boolean
    onlyOffline: boolean
    productId: number
    productKoreanName: string
    productName: string
    thumbnailUrl: string
    productSizes?: ProductSize[]
}

export type ProductSize = {
    actualMeasures?: Record<string, string>
    isSoldOut: boolean;
    isLast: boolean;
    productId: number
    productSizeId: number
    sizeName: string
    sort: number
}

export type ProductSizeFilterDTO = {
    gender: string
    brand: {
        brandId: number
        brandName: string
    }
    category: []
    price: number[]
    size: string[]
}

export type RelatedProducts = {
    productFittingCuts: RelatedProduct[]
}

export type RelatedProduct = {
    brandName: string
    currentPrice: number
    productId: number
    productName: string
    thumbnailUrl: string
}

export type BookmarkRequest = {
    targetId: number
    targetType: 'BRAND' | 'PRODUCT'
}

export type BookmarkResponse = {
    id: number
    name: string
}

export type CartResponse = {
    carts: TCartItem[]
    deliveryPrice: number
}

export type TCartItem = {
    cartId: string
    count: number
    product: CartProduct
    size: CartSize
    sku: string
    userId?: number
}

export type CartProduct = {
    brandName?: string
    color: string
    currentPrice: number
    id: number
    initialPrice: number
    midsale: boolean
    name: string
    thumbnailUrl?: string
    onlyOne: boolean
    unavailableMileage: boolean
    unavailableCoupon: boolean
    availableStockUser: number
}

export type CartSize = {
    isSoldOut: boolean;
    isLast: boolean;
    id: number;
    name?: string;
}

export type CartAddRequest = {
    count?: number
    sizeId?: number
    token?: string
}

export type ProductListSearchOption = {
    mainCategoryId?: number
    brandId?: number
    subcategoryId?: number
    secondCategoryId?: number
    searchKeyword?: string
    genderCodes?: string
    categoryCodes?: string
    productSize?: string
    sale?: boolean
    midSale?: boolean
    pageNumber?: number
    pageSize?: number
    sort?: any
    page: number
    size: number
}

export type TCategory = {
    categoryId: number
    categoryName: string
    sort: number
}

export type TSubCategory = {
    categoryCodes: string[]
    createdAt: string
    name: string
    nth: number
    parentCategoryId: number
    subType?: 'GROUP' | 'CATEGORY'
    sort: number
    subcategoryId: number
    visible: boolean
}

export type OrderReady = {
    createdAt: string
    customerName: string
    customerPhone: string
    deliveryPrice: number
    earnMileageRate: number
    id: number
    orderNumber: string
    products: OrderProduct[]
    totalPrice: number
}

export type OrderProduct = {
    brandName: string
    color: string
    colorKoreanName: string
    count: number
    discountedUnitPrice: number
    latestRequestStatus: OrderRequestStatus
    latestRequestType: 'EXCHANGE' | 'RETURN'
    latestRequestProductStatus: ProductStatus
    latestRequestShippingCompany: string
    latestRequestShippingNumber: string
    orderProductId: number
    productCode: string
    productId: number
    productKoreanName: string
    productName: string
    initialUnitPrice: number;
    productStatus: ProductStatus
    refundStatus: string
    shippingCompany: string
    shippingNumber: string
    sizeName: string
    thumbnailUrl: string
    totalPrice: number
    unitPrice: number
}

export type ProductStatus = 'CANCELED' | 'CONFIRMED' | 'DELIVERED' | 'IN_DELIVERY' | 'IN_RETURN' | 'NONE' | 'PENDING' | 'READY_EXCHANGE' | 'READY_RETURN' | 'RETURNED' | 'RETURNED_FAILED'
export type OrderStatus = 'CANCELED' | 'CONFIRMED' | 'DELIVERED' | 'IN_DELIVERY' | 'PENDING' | 'READY'
export type OrderRequestStatus =
    'CANCELED'| 'COMPLETED'| 'DELIVERED'| 'IN_DELIVERY'| 'NONE'| 'PENDING'| 'READY'| 'READY_DELIVERY'| 'REFUNDED'| 'RETURNED'| 'RETURNED_FAILED'

export type OrderPayStarting = {
    customerName: string
    customerPhone: string
    mobileReturnUrl?: string
    newUserAddress?: UserAddressCreateDto
    payMethod: PayMethod
    receiverAddress?: string
    receiverDetailAddress?: string
    receiverName: string
    receiverPhone: string
    receiverPostcode: string
    shippingRequest?: string
    usedCouponCode: string
    usedCouponIds: number[]
    usedMileage: number
    userAddressId?: number
}

export type OrderHistorySearchOption = {
    keyword?: string
    store?: 'ONLINE' | 'OFFLINE'
}

export type OrderSummary = {
    orderNumber: string
    orderedAt?: string
    products: OrderProduct[]
    requestStatus?: 'COMPLETED' | 'DELIVERED' | 'IN_DELIVERY' | 'PENDING' | 'READY' | 'READY_DELIVERY' | 'REFUNDED' | 'RETURNED'
    requestType?: 'EXCHANGE' | 'RETURN'
    shippingCompany?: string
    shippingNumber?: string
    shippingStatus?: 'DELIVERED' | 'IN_DELIVERY' | 'READY'
    status?: 'CANCELED' | 'CONFIRMED' | 'PAID' | 'PENDING_CANCEL' | 'READY' | 'WAIT'
    store: 'CARHARTT' | 'OFFLINE' | 'RAFFLE' | 'WORKSOUT' | string
    totalPrice: number
}

export type TOrderDetail = {
    addressName: string
    appliedCoupons: OrderCouponApplyInfoDto[]
    cashReceiptUrl?: string
    couponDiscount: number
    customerName?: string
    customerPhone?: string
    deliveryPrice: number
    discountPrice: number
    earnedMileage: number
    isOffline: boolean
    orderId: number
    orderNumber: string
    orderRequestExtra?: OrderRequestExtra
    orderRequestId?: number
    orderedAt?: string
    paidAt?: string
    paidPrice: number
    payMethod: PayMethod
    productPrice: number
    products: OrderPaidProduct[]
    receiptUrl?: string
    receiverAddress: string
    receiverDetailAddress: string
    receiverName: string
    receiverPhone: string
    receiverPostcode: string
    shippingStatus: 'DELIVERED' | 'IN_DELIVERY' | 'READY'
    shippingRequest: string
    status: 'CANCELED' | 'CONFIRMED' | 'PAID' | 'PENDING_CANCEL' | 'READY' | 'WAIT'
    totalPrice: number
    usedMileage: number
    userAddressId: number
    userCode?: string
    userId?: number
    virtualAccountBank?: string
    virtualAccountNumber?: string
    virtualAccountOwner?: string
    virtualAccountValid?: string
    canceledUsedMileage?: number
    canceledBalance?: number
}

export type OrderCouponApplyInfoDto = {
    code?: string
    couponId: number
    name: string
}

export type OrderRequestExtra = {
    addressName?: string
    canceledAt?: string
    canceledMileage?: number
    completedAt?: string
    payMethod: PayMethod
    products: OrderPaidProduct[]
    refundAccountBank?: string
    refundAccountName?: string
    refundAccountNumber?: string
    refundedAt?: string
    refundedPrice?: number
    requestDeliveryPrice?: number
    requestOrderNumber: string
    requestPrepaidPrice?: number
    requestedAt?: string
    receiverPostcode?: string
    refundedDelivery?: boolean
    shippingRequest?: string
    status: OrderRequestStatus
    totalPaidPrice?: number
    type: 'EXCHANGE' | 'RETURN'
    virtualAccountBank: string
    virtualAccountNumber: string
    virtualAccountOwner: string
    virtualAccountValid: string
    receiverName: string
    receiverPhone: string
    receiverAddress: string
    receiverDetailAddress: string
    userAddressId?: number
    usedMileage?: number
}

export type OrderPaidProduct = {
    brandName: string
    cancelReason?: string
    color: string
    count: number
    couponDiscountPrice: number
    discountedUnitPrice: number
    earnedMileage: number
    latestRequest?: 'EXCHANGE' | 'RETURN'
    latestRequestChangingSizeId?: number
    latestRequestId?: number
    latestRequestOrderNumber?: string
    latestRequestProductId?: number
    latestRequestReason?: string
    latestRequestShippingCompany?: string
    latestRequestShippingTrackingNumber?: string
    latestRequestSizeName?: string
    latestRequestStatus?: 'COMPLETED' | 'DELIVERED' | 'IN_DELIVERY' | 'PENDING' | 'READY' | 'READY_DELIVERY' | 'RETURNED' | 'CANCELED'
    orderProductId: number
    productId: number
    productName: string
    productStatus: 'CANCELED' | 'CONFIRMED' | 'DELIVERED' | 'IN_DELIVERY' | 'IN_RETURN' | 'NONE' | 'PENDING' | 'RETURNED'
    refundStatus: 'NONE' | 'PENDING' | 'REFUNDED'
    requestHistories: OrderProductRequestDto[]
    shippingCompany?: string
    shippingTrackingNumber?: string
    sizeId: number
    sizeName: string
    thumbnailUrl: string
    totalPrice: number
    unitPrice: number
    usedMileage: number
    unavailableMileage: boolean
    unavailableCoupon: boolean
}

export type OrderProductRequestDto = {
    createdAt?: string
    orderProductId: number
    orderRequestId?: number
    productStatus: ProductStatus
    reason: string
    requestOrderNumber: string
    requestType: 'EXCHANGE' | 'RETURN'
    returnedAt?: string
    shippingCompany?: string
    shippingTrackingNumber?: string
    sizeId: number
    sizeName: string
}

export type MileageType =
    'ADMIN_DEDUCT'
    | 'ADMIN_PROVIDE'
    | 'ORDER_EARN'
    | 'ORDER_EARN_CANCEL'
    | 'ORDER_USE'
    | 'ORDER_USED_RETURN'
    | 'CONFIRM_CANCELED'

export type UserMileageHistoryResponse = {
    createdAt: string
    mileageAmount: number
    type: MileageType
    userMileageHistoryId: number
}

export type ViewUserCoupon = {
    couponId: number
    couponType: 'CART_DISCOUNT' | 'FREE_SHIPPING'
    issuedType: 'ISSUED' | 'DOWNLOAD' | 'DISCOUNT_KEY'
    issuedDateStr?: string
    name?: string
    usedCouponType: 'DOWN_N' | 'DOWN_Y' | 'USE_C' | 'USE_N' | 'USE_Y'
    validDateStr?: string
}

export type CustomerBoardList = {
    contents?: string
    createdAt: string
    customerBoardId: number
    isReplied: boolean
    repliedAt?: string
    title: string
    userName: string
}

export type UserAddress = {
    address: string
    addressId: number
    addressName: string
    detailAddress: string
    gugun: string
    isDefault: boolean
    phone: string
    postcode: string
    receiverName?: string
    requestMessage?: string
    sido: string
}

export type OrderStart = {
    approvalKey?: string
    mobilePayUrl?: string
    orderNumber: string
    totalPrice: number
    traceNo: string
}

export type OrderResult = {
    expiredAt?: string
    mobileReturnUrl?: string
    orderNumber: string
    orderedAt: string
    payMethod: PayMethod
    productNames: string
    totalPrice: number
    virtualAccountBank?: string
    virtualAccountNumber?: string
    virtualAccountOwner?: string
    receiverName?: string
    receiverPhone?: string
    receiverAddress?: string
    receiverDetailAddress?: string
}

export type FaqResponse = {
    content: string
    faqId: string
    title: string
}

export type OrderCancelRequest = {
    bankCode?: 'BS' | ' CT' | ' DG' | ' HN' | ' HS' | ' JB' | ' JJ' | ' K' | ' KJ' | ' KM' | ' KN' | ' KU' | ' NH' | ' PO' | ' SC' | ' SH' | ' SIN' | ' SL' | ' SM' | ' SU' | ' SUP' | ' WR'
    bankNumber?: string
    bankOwner?: string
    cancelOrderProducts?: CancelOrderProduct[]
    customerName?: string
    customerPhone?: string
}

export type CancelOrderProduct = {
    cancelOrderProductId?: number
    cancelReason?: string
}

export type OrderRequestRequestDto = {
    customerName?: string
    customerPhone?: string
    orderProductIds: number[]
    orderRequestType: 'EXCHANGE' | 'RETURN' | 'CANCEL'
}

export type OrderRequestPrepaidDto = {
    deliveryAmount: number
    totalPaidProductAmount: number
    totalPrepayingAmount: number
    willReturnMileage: number
    willCancelAmount?: number
}

export type OrderRequestCreateDto = {
    mobileReturnUrl?: string
    newUserAddress?: UserAddressCreateDto
    orderProducts: OrderRequestProductDto[]
    orderRequestType: 'EXCHANGE' | 'RETURN'
    payMethod: PayMethod
    reason?: string
    receiverAddress: string
    receiverDetailAddress: string
    receiverName: string
    receiverPhone: string
    receiverPostcode: string
    refundAccountBank?: string
    refundAccountName?: string
    refundAccountNumber?: string
    requesterName?: string
    requesterPhone?: string
    shippingRequest?: string
    userAddressId?: number
}

export type OrderRequestProductDto = {
    orderProductId?: number
    reason?: string
    sizeId?: number     //사이즈 변경시에만 입력
}

export type CustomerBoardDto = {
    contents: string
    title: string
}

export type CustomerBoard = {
    contents?: string
    createdAt?: string
    id: number
    source: 'CARHARTT' | 'WORKSOUT'
    title: string
    updatedAt?: string
    userId: number
    visible: boolean
}

export type CustomerBoardDetailDto = {
    attachmentUrls: string[]
    contents?: string
    customerBoardId: number
    replies: CustomerBoardReplyDto[]
    title: string
}

export type CustomerBoardReplyDto = {
    contents?: string
    createdAt: string
    customerBoardReplyId: number
    isManagerAnswer: boolean
}


export type WorksTypeEnum = 'HEAD_LOGISTICS' | 'OFFLINE_STORE'

export type WorkWithUsListParams = {
    type?: WorksTypeEnum
    group?: number
    page?: number
    size?: number
}

export type WorksWithUsDto = {
    content: string
    createdAt?: string
    group: WorksWithUsGroupDto
    id: number
    isActive: boolean
    recruitFrom?: string
    recruitUntil?: string
    resumeFormUrl?: string
    role: WorksWithUsRoleDto
    sort?: number
    title: string
    type: WorksTypeEnum
    workplace: WorksWithUsWorkplaceDto
}

export type WorksWithUsGroupDto = {
    group: string
    id: number
}

export type WorksWithUsRoleDto = {
    id: number
    role: string
}

export type WorksWithUsWorkplaceDto = {
    id: number
    workplace: string
}

export type UserInfoModifyDto = {
    address: UserAddressCreateDto
    gender: 'ETC' | 'FEMALE' | 'MALE'
    birth?: string
    name: string
    phone: string
    agreements?: AgreementType[]
    password: string;
    newPassword: string;
    confirmPassword: string;
}

export type ChangePasswordDto = {
    password: string;
    newPassword: string;
    confirmPassword:string;
}

export type FindPasswordDto = {
    newPassword: string;
    tokenVersionId:string;
}

export type UserAddressCreateDto = {
    address: string
    addressName: string
    detailAddress: string
    gugun: string
    isDefault?: boolean
    phone: string
    postcode: string
    receiverName?: string
    requestMessage?: string
    sido: string
    userAddressId?: number
}

export type ViewUserCouponDto = {
    couponId: number
    couponType: 'CART_DISCOUNT' | 'FREE_SHIPPING'
    issuedType: 'ISSUED' | 'DOWNLOAD' | 'DISCOUNT_KEY'
    issuedDateStr?: string
    name?: string
    usedCouponType: 'DOWN_N' | 'DOWN_Y' | 'USE_C' | 'USE_N' | 'USE_Y'
    validDateStr?: string
    couponCode: string
    discountedRate: number
    id: number
}

export type CouponApplyProductDto = {
    productId: number
    isApply: string
}

export type OrderHistoryQueryRequest = {
    customerName?: string
    customerPhone?: string
    orderProductIds?: number[]
}

export type OrderChangeAddressDto = {
    customerName?: string
    customerPhone?: string
    newUserAddress?: UserAddressCreateDto
    receiverAddress: string
    receiverDetailAddress: string
    receiverName: string
    receiverPhone: string
    receiverPostcode: string
    shippingRequest?: string
    userAddressId?: number
}

export type OrderChangeSizeDto = {
    customerName?: string
    customerPhone?: string
    items: OrderChangeSizeItemDto[]
}

export type OrderChangeSizeItemDto = {
    changeToProductSizeId?: number
    orderProductId?: number
}

export type ProductSizeByCategoryDto = {
    sizes: ProductSizeCode[]
    subcategoryId: number
    subcategoryName: number
}

export type ProductSizeCode = {
    sizeClassCode: string
    sizeName: string
}

export type CustomerCenterButtonDto = {
    button: string
    id: number
    site: 'CARHARTT' | 'WORKSOUT'
    sort: number
    active: boolean
}

export type CustomerCenterDto = {
    active: boolean
    button: string
    content: string
    createdAt?: string
    id: number
    site: 'CARHARTT' | 'WORKSOUT'
    sort?: number
    title: string
}

export type CustomerMailRequest = {
    contents: string
    email: string
    name: string
    title: string
    type: MailRequestType
}

export type MailRequestType = 'MARKETING' | 'PARTNERSHIP' | 'RECRUITING' | 'WEBSITE & ONLINE STORE'

export type OrderDiscountRequest = {
    usingCouponCode?: string
    usingMileage?: number
    usingUserCouponIds: number[]
}

export type CouponApplyResult = {
    deliveryPrice: number
    finalTotalProductsPrice: number
    orderProducts: CouponApplyProductResult[]
    totalDiscountedAmount: number
    totalDiscountedAmountByCoupon: number
    totalDiscountedAmountByMileage: number
}

export type CouponApplyProductResult = {
    discountedAmount: number
    discountedAmountByCoupon: number
    discountedAmountByMileage: number
    unavailableMileage: boolean
    unavailableCoupon: boolean
    finalProductPrice: number
    orderProductId: number
}

export type UserFindEmailDto = {
    phone: string
}

export type UserFindPasswordDto = {
    email: string
    newPassword?: string
    phone: string
}

export interface ServiceResponse<T = null> {
    code: string;
    payload: T | null;
    message?: string | null;
}


export interface PushItemDTO {
    targetType: TargetType;
    targetId: number;
    name: string;
    sort?: number;
}

export interface PushItemBrandDto extends PushItemDTO {
    imageUrl?: string;
    description?: string;
}

export interface PushItemProductDto extends PushItemDTO {
    brandName: string;
    initialPrice: number;
    currentPrice: number;
    discountedRate: number;
    midsale: boolean;
    newArrival: boolean;
    thumbnailUrl?: string;
}

export interface NiceServiceDto {
    tokenVersionId: string;
    encData: string;
    integrityValue: string;
}

export interface NiceRequestDto {
    returnUrl: string,
    verifyType: VerityType,
    userEmail?: string,
}

export interface UserNiceDto {
    verificationNiceId: number;
    userName: string;
    userPhone: string;
    userBirth: string;
    userGender: 'ETC' | 'FEMALE' | 'MALE';
}

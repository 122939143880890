import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import { Icon } from './Icon';
import { isMobile } from "react-device-detect";
import {useHistory, useLocation} from "react-router-dom";

export const OpenPopup = () => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const history = useHistory();
  const { pathname } = useLocation();
  const [_isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(isMobile);
  }, [isMobile]);

  const getNiceMessage = (code:string): string => {
    switch (code) {
      case '0000':
        return '본인인증이 완료되었습니다.';
      case '0001':
        return '이미 가입되어 있는 전화번호입니다.';
      case '0002':
        return '계정 전화번호와 인증 전화번호가 일치하지 않습니다.';
      case '0003':
        return '만 14세 미만일 경우 웍스아웃 회원가입이 불가능합니다.<br>이용에 불편을 드려서 죄송합니다.';
      case '0004':
        return  '- 회원 탈퇴 후 재 가입은 30일 이후에 가능합니다.<br> - 회원 탈퇴 후 6개월(180일) 내에 재 가입 시 회원 가입 쿠폰은 재 발급되지 않습니다.<br> - 회원 탈퇴 후 보유하신 포인트와 쿠폰은 모두 소멸되며 복구되지 않습니다.<br> - 기타 문의는 채널톡으로 문의 부탁드립니다.';
      default:
        return '본인인증에 실패하였습니다.<br>고객정보를 확인해주세요.';
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);
    const niceResultCode = queryParams.get("niceResultCode");
    if (niceResultCode !== null) {
      setOpen(true);
      setText(getNiceMessage(niceResultCode));
    }
  }, [history]);

  const handleClose = () => {
    setOpen(false)
    const queryParams = new URLSearchParams(history.location.search);
    const tokenVersionId = queryParams.get("tokenVersionId");

    history.replace(pathname + '?tokenVersionId=' + (tokenVersionId ?? ''));
  };

  return (
      <>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className={`flex md:justify-end justify-center items-end w-full popupToast`}
        >
          <div
              style={{width:`${_isMobile ? '300px': '350px'}`}}
              className={`flex flex-col py-4 px-5 gap-y-4 max-w-xl w-full bg_3e52bb text-white`}>
            <div className={`flex justify-between`}>
              <p className={`text-13 leading-6`}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </p>
              <Icon.X className={`wh-6 cursor-pointer bg-gray-200 rounded-full p-1`} onClick={handleClose} />
            </div>
            <button
                className={`bg-white tx_3e52bb w-full text-center py-2 text-13`}
                onClick={handleClose}
            >
              확인
            </button>
          </div>
        </Dialog>
      </>
  );
};

import React, {FC} from 'react';
import {Button} from './Button';
import {Icon} from './Icon';

interface PopUpProps {
    title: string;
    description?: string;
    buttonText: string;
    subButtonText?: string;
    subButtonOnClick?: () => void;
    open: boolean;
    chTalkButton?: string;
    onClick?: () => void;
    onClickMobile?: () => void;
    onClose: () => void;
}

export const PopUp: FC<PopUpProps> = ({
                                          title,
                                          description,
                                          buttonText,
                                          onClick,
                                          onClickMobile,
                                          onClose,
                                          open,
                                      }) => {
    if (!open) return <></>;
    return (
        <>
            <div style={{zIndex:'999999999'}} className="fixed inset-0 bg-littleblack flex items-center justify-center overflow-hidden">
                <div className="w-3/4 md:w-96 flex flex-col  bg-white overflow-hidden p-6 space-y-4">
                    <div className="flex flex-row justify-between">
                        <p className="text-16 font-semibold">{title}</p>
                        <button
                            onClick={onClose}
                            className="flex flex-row space-x-2 items-center"
                        >
                            <Icon.X/>
                        </button>
                    </div>
                    {description &&
                        description.indexOf('\n') > -1 ?
                            description?.split('\n').map((str, index) => (
                                <p key={`popup_description_${index}`} className="text-15 font-normal">{str}</p>
                            ))
                            :
                            <p className="text-15 pb-6">{description}</p>
                    }
                    <Button
                        onClick={() => {
                            onClick?.();
                            onClose();
                        }}
                        text={buttonText}
                        className="hidden md:block text-15 font-normal filled-gray-900"
                    />

                    <Button
                        onClick={() => {
                            onClickMobile?.();
                            onClose();
                        }}
                        text={buttonText}
                        className="md:hidden text-15 font-normal filled-gray-900"
                    />
                </div>
            </div>
        </>
    );
};

export const BluePopUp: FC<PopUpProps> = ({
                                              title,
                                              description,
                                              buttonText,
                                              subButtonText,
                                              subButtonOnClick,
                                              onClick,
                                              chTalkButton,
                                              onClickMobile,
                                              onClose,
                                              open,
                                          }) => {
    if (!open) return <></>;
    return (
        <>
      <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center overflow-hidden text-white">
        <div className="w-3/4 md:w-96 flex flex-col bg_3e52bb overflow-hidden p-6 space-y-4">
          <div className="flex flex-row justify-between relative">
            <div className="flex flex-col">
              {title.split('\n').map((str, index) => (
                <p
                  key={`popup_title_${index}`}
                  className="text-13 font-semibold">
                  {str}
                </p>
              ))}
            </div>
            <button onClick={onClose} className="flex space-x-2 w-4 absolute right-0 -top-1">
              <Icon.X />
            </button>
          </div>

          {description &&
              <p className="text-12 font-normal">
                {description.split('\n').map((str, index) => (
                    <span key={`popup_description_${index}`}>{str}<br/></span>
                ))}
              </p>
          }
          {(subButtonText && subButtonOnClick) ? (
              <div className={'flex justify-between space-x-4'}>
                <Button
                    onClick={() => {
                      subButtonOnClick?.();
                      onClose();
                    }}
                    text={subButtonText}
                    className="text-12 bg-white py-1 h-8 rounded-sm flex-1 text-[#3e52bb]"
                />
                <Button
                    onClick={() => {
                      onClick?.();
                      onClose();
                    }}
                    text={buttonText}
                    className="text-12 bg-white py-1 h-8 rounded-sm flex-1 text-[#3e52bb]"
                />
              </div>
          ) : (
            chTalkButton ? (
                <div className={`flex gap-x-4`}>
                    <Button
                        onClick={() => {
                            onClick?.();
                            onClose();
                        }}
                        text={`확인`}
                        className={`text-12 bg-white py-1 h-8 rounded-sm flex-1 text-[#3e52bb]`}
                    />
                    <Button
                        text={buttonText}
                        className={`text-12 bg-white py-1 h-8 rounded-sm flex-1 text-[#3e52bb] ${chTalkButton}`}
                    />
                </div>
            ):(
                <Button
                    onClick={() => {
                        onClick?.();
                        onClose();
                    }}
                    text={buttonText}
                    className="text-12 bg-white py-1 h-8 rounded-sm flex-1 text-[#3e52bb]"
                />
            )
          )}
        </div>
      </div>
    </>
    );
};

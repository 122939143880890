import React, {FC, useEffect} from 'react';
import { TCartItem } from '../types/types';
import { CounterBadge } from './CounterBadge';
import {useUserDispatch, useUserState} from "../context/UserContext";

interface CartCounterBadgeProp {
  carts: TCartItem[];
}

export const sumTotalCountOfCarts = (carts: TCartItem[]): number => (
  carts
    .map(({ count }) => count)
    .reduce((sum, count) => sum + count, 0)
);


/**
 * TODO:
 *   장바구니 아이콘 뱃지 : useRef 또는 Redux 를 사용하여 리팩토링 필요.
 *   -
 *   useRef 또는 Redux 를 사용한 접근을 위해서는, 작업의 크기가 과하게 커질 수 있습니다.
 *   좀 더 가벼운 방식의 해결을 위해 임시로 컴포넌트 키를 dom 에 묻어두고,
 *   document.querySelectorAll 을 사용해 업데이트 하는 방식으로 접근하였습니다.
 *   -
 *   이러한 접근 방법은 작업 배경상 선택한 것이며, 비용이 낮은 접근일 뿐 올바른 접근은 아닙니다.
 *   예를들어 동일한 dom 을 보장하지 않는다면 side-effect 를 일으킬 우려가 있습니다.
 *   따라서 react 에서 일반적으로 채택하고 있는 useRef 또는 Redux 등으로의 개선이 필요합니다.
 *
 *   context 사용하여 처리하였습니다 (성원)
 */

const REF_NAME = 'CartCounterBadge';

export const CartCounterBadge: FC<CartCounterBadgeProp> = ({ carts }) => {
  const {cartCount} = useUserState();

  return (
      <CounterBadge
          count={cartCount}
          hiddenZero={true}
          data-ref-name={REF_NAME}
      />
  );
};

/**
 * 장바구니 카운터 뱃지 컴포넌트들의 상태를 최신화 하므로
 * 사실상의 dispatcher 역할을 합니다.
 * 하지만 redux 의 실제 dispatcher 와 오인할 수 있으므로
 * update 라는 prefix 를 사용합니다.
 *
 * @param carts
 */
export const updateCartCounterBadge = (carts: TCartItem[]) => {
  const badges = document.querySelectorAll(`[data-ref-name="${REF_NAME}"]`);
  badges.forEach(badge => {
    badge.textContent = sumTotalCountOfCarts(carts).toString();
  });
};

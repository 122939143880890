const isLoggingEnabled = process.env.REACT_APP_LOGGING !== 'false'; // 기본값을 true로 설정

const debug = {
  log: (...args: any) => {
    if (isLoggingEnabled) {
      console.log('[DEV]:',...args);

    }
  },
  error: (...args: any) => {
    if (isLoggingEnabled) {
      console.error('[DEV]:',...args);
    }
  }
}
export default debug
export const AIRBRIDGE_EVENT = {
  signUp: 'airbridge.user.signup',
  signIn: 'airbridge.user.signin',
  signOut: 'airbridge.user.signout',
  productView: 'airbridge.ecommerce.product.viewed',
  addToCart: 'airbridge.ecommerce.product.addedToCart',
  orderComplete: 'airbridge.ecommerce.order.completed',
  orderCancel: 'airbridge.ecommerce.order.canceled',
  homeScreen: 'airbridge.ecommerce.home.viewed',
  productCatalog: 'airbridge.ecommerce.productList.viewed',
  searchResults: 'airbridge.ecommerce.searchResults.viewed',
  raffleApply: 'raffle_apply',
};

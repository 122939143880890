import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import {useHistory} from "react-router-dom";



interface Props {}
dayjs.extend(duration);
export const CountDown: React.FC<Props> = () => {
  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [timerStarted, setTimerStarted] = useState<boolean>(false);
  const { push } = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs();
      const start = dayjs('2023-02-08 00:00:00', 'YYYY-MM-DD hh:mm:ss');
      const end = dayjs('2023-02-14 23:59:59', 'YYYY-MM-DD hh:mm:ss');
      if (now >= start && now <= end) {
        setTimerStarted(true);
        const countdown = dayjs.duration(end.diff(now));
        setDays(countdown.days());
        setHours(countdown.hours());
        setMinutes(countdown.minutes());
        setSeconds(countdown.seconds());
      } else {
        setTimerStarted(false);
        clearInterval(interval);
      }
    }, 1);
    return () => clearInterval(interval);
  }, []);

  if (!timerStarted) {
    return <></>

  }
  return (
      <div
          onClick={() => push(`/journal/563`)}
          className="flex flex-col text-white max-w-4xl items-center cursor-pointer bg-brand-1 py-3 rounded-xl md:mx-0 md:my-0 mx-2 my-2 md:-mt-2 md:w-full"
      >
        <div className={`text-lg cursor-pointer`}>쿠폰 적용 잔여시간</div>
        <div className={`cursor-pointer md:pt-1`}>
          <div className="flex md:text-7xl text-6xl w-96 justify-center">
            <div>
              <span className="font-red-ani font-light">{days.toString().padStart(2, '0')}</span>
              <span className="font-red-ani">:</span>
              <div className="text-12 flex justify-center pr-4">day</div>
            </div>
            <div>
              <span className="font-red-ani font-light">{hours.toString().padStart(2, '0')}</span>
              <span className="font-red-ani">:</span>
              <div className="text-12 flex justify-center pr-4">hour</div>
            </div>
            <div>
              <span className="font-red-ani font-light">{minutes.toString().padStart(2, '0')}</span>
              <span className="font-red-ani">:</span>
              <div className="text-12 flex justify-center pr-4">minute</div>
            </div>
            <div className="lg:w-40 w-20">
              <span className="font-red-ani font-light">{seconds.toString().padStart(2, '0')}</span>
              <div className="text-12 flex justify-center">second</div>
            </div>
          </div>
        </div>
        <div className={`md:text-xl text-16 flex flex-col md:pt-4 pt-3`}>
          + 20% COUPON CODE : TAKE20EXTRAOFF
          <div className={`flex justify-center`}>
            <button className="w-60 flex mt-3 flex justify-center ml-2 text-12 p-1 bg_3e52bb text-white rounded-md">
              자세히 보기 &gt;
            </button>
          </div>
        </div>
      </div>
  );
}
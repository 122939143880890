import React, {FC, InputHTMLAttributes, useRef} from 'react';
import {ReactComponent as SearchIcon} from '../assets/svg/icon-search.svg';
import {Icon} from './Icon';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
    onSearch?: () => void;
    className?: string;
}

export const SearchField: FC<SearchProps> = ({
                                                 value,
                                                 onSearch = () => {
                                                 },
                                                 className,
                                                 ...props
                                             }) => {
    const input = useRef<HTMLInputElement>(null);

    return (
        <form
            className={`${className} md:w-1/3 flex items-center px-4 h-8 bg-gray-100 rounded-full focus-within:border-gray-800`}
            onSubmit={(e) => {
                e.preventDefault();
                onSearch();
            }}
        >
            <div className="flex justify-center items-center -ml-2 wh-10">
                <SearchIcon className="text-brand-1 fill-current"/>
            </div>

            <input
                ref={input}
                className="flex-1 text-sm placeholder-brand-1 bg-transparent"
                value={value}
                {...props}
            />

            {value && (
                <div className="flex justify-center items-center -mr-2 wh-10">
                    <Icon.XRound
                        className="wh-4"
                        onClick={() => {
                            Object.getOwnPropertyDescriptor(
                                window.HTMLInputElement.prototype,
                                'value'
                            )?.set?.call(input.current, '');
                            input.current?.dispatchEvent(
                                new Event('change', {bubbles: true})
                            );
                        }}
                    />
                </div>
            )}
        </form>
    );
};

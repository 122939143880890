import React, { FC } from 'react';

interface CounterBadgeProp {
  count: number;
  hiddenZero?: boolean;
}
export const CounterBadge: FC<CounterBadgeProp> = ({
  count,
  hiddenZero = true,
  ...props
}) => {
  return (
      <span id={'counter-badge'}
          className={`text-11 font-extralight pt-0.5 bg_3e52bb text-gray-100 absolute wh-5 rounded-full text-center ${
              // 런타임 환경에서 개발자도구를 통해 디버깅 하기 용이하도록
              // <></> 를 반환하기 않고 뱃지 ui 를 그대로 숨긴 상태로 반환하도록 동작합니다.
              (count === 0 && hiddenZero) ? 'hidden' : ''
          }`}
          style={{
            top: 'calc(45% - 1rem)',
            right: 'calc(45% - 1rem)',
            opacity: 1,
          }}
          {...props}
      >{count}</span>
  );
};

import {AdvancedMatching, Options} from "react-facebook-pixel";
import debug from '../utils/logging.js';
declare global {
    interface Window {
        ChannelIO: Function;
        attachEvent: Function;
        ChannelIOInitialized: boolean;
    }
}

const ChannelService = {
    loadScript: () => {
        const w = window;
        if (w.ChannelIO) {
            return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
        }
        function ch () {
            ch.c(arguments)
        }
        // @ts-ignore
        ch.q = [];
        ch.c = (args: any) => {
            ch.q.push(args);
        };
        w.ChannelIO = ch;
        function l() {
            if (w.ChannelIOInitialized) {
                return;
            }
            w.ChannelIOInitialized = true;
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
            s.charset = 'UTF-8';
            var x = document.getElementsByTagName('script')[0];
            x.parentNode?.insertBefore(s, x);
        }
        if (document.readyState === 'complete') {
            l();
        } else if (window.attachEvent) {
            window.attachEvent('onload', l);
        } else {
            window.addEventListener('DOMContentLoaded', l, false);
            window.addEventListener('load', l, false);
        }
    },
    boot: (settings: any) => {
        window.ChannelIO('boot', settings, function callback(error: any, user: any){
            if (error) {
                debug.error(error);
            } else {
                debug.log('boot success');
            }
        });
    },
    shutdown: () => {
        window.ChannelIO('shutdown');
    }
}

export default ChannelService;

import debug from '../utils/logging.js';

const log = (name: string, ...args: any[]) =>
  debug.log('[Naver Pixel]', `(${name})`, '\t', ...args);

const commonKey = process.env.REACT_APP_NAVER_COMMON_KEY || '';
const domain = (process.env.REACT_APP_URL || '')
  .replace(/^(http:\/\/|https:\/\/)/, '')
  .replace(/\/\s*$/, '');

const makeScript = (type?: string, value?: string) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = makeInnerHTML(type, value);

  document.body.appendChild(script);
  document.body.removeChild(script);
};

const makeInnerHTML = (type?: string, value?: string) => {
  let innerHTML = `
    var _nasa={};
  `
  if (type && value) {
    innerHTML += `
      if(window.wcs) _nasa["cnv"] = wcs.cnv("${type}","${value}");
    `
  }
  innerHTML += `
        if (!wcs_add) var wcs_add = {};
        wcs_add["wa"] = "${commonKey}";
        if (!_nasa) var _nasa = {};
        if (window.wcs) {
            wcs.inflow("${domain}");
            wcs_do(_nasa);
        }
    `;
  return innerHTML;
};

const NaverPixel = {
  loaded: () => {
    debug.log('\n');
    log('module loaded');
  },
  init: (_key: string, _domain: string): void => {
    log('init');
    // commonKey = _key
    // domain = _domain.replace(/^http:\/\/\/\s*$/, "").replace(/\/\s*$/, "");
  },
  pageView: (pathname?: string) => {
    log('pageView', pathname);
    makeScript();
  },
  addToCart: (data?: object) => {
    log('AddToCart');
    makeScript('3', '1');
  },

  purchase: (price: number) => {
    log('Purchase', price);
    makeScript('1', price.toString());
  },

  // 등록 관련 (등록 == 가입)

  completeRegistration: (data?: object) => {
    // 등록양식 작성 완료 이벤트
    log('CompleteRegistration', data);
    //makeTransitionScript("2", "1")
  },
  lead: (data?: object) => {
    // 등록완료 이벤트
    log('Lead', '\t', data);
    makeScript('2', '1');
  },
};

export default NaverPixel;

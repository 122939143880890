import {createContext, Dispatch, useContext, useReducer} from 'react';
import {ProductQuery, UserInfo} from '../types/types';

type UserState = {
    isLoggedIn?: boolean;
    userInfo?: UserInfo;
    cartCount: number;
    products: ProductQuery[];
    lastQuery: string;
    setSale?: string;
    setMidSale?: string;
};

type UserAction =
    | { type: 'LOGIN'; payload: UserInfo }
    | { type: 'LOGOUT' }
    | { type: 'SET_CART_COUNT'; payload: number }
    | { type: 'SET_PRODUCTS'; payload: ProductQuery[] }
    | { type: 'RESET_PRODUCTS' }
    | { type: 'SET_LAST_QUERY'; payload: string }
    | { type: 'SET_SALE'; payload: string }
    | { type: 'SET_MID_SALE'; payload: string }

type UserDispatch = Dispatch<UserAction>;

const UserStateContext = createContext<UserState | undefined>(undefined);
const UserDispatchContext = createContext<UserDispatch | undefined>(undefined);

const userState: UserState = {
    isLoggedIn: undefined,
    cartCount: 0,
    products: [],
    lastQuery: '',
    setSale: 'false',
    setMidSale: 'false',
};

const reducer = (state: UserState, action: UserAction) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isLoggedIn: true,
                userInfo: action.payload,
            };
        case 'LOGOUT':
            return {
                ...state,
                isLoggedIn: false,
                userInfo: undefined,
            };
        case 'SET_CART_COUNT':
            return {
                ...state,
                cartCount: action.payload,
            };
        case 'SET_PRODUCTS':
            return {
                ...state,
                products: action.payload,
            };
        case 'RESET_PRODUCTS':
            return {
                ...state,
                products: [],
            };
        case 'SET_LAST_QUERY':
            return {
                ...state,
                lastQuery: action.payload,
            };
        case 'SET_SALE':
            return {
                ...state,
                setSale: action.payload,
            };
        case 'SET_MID_SALE':
            return {
                ...state,
                setMidSale: action.payload,
            };
        default:
            return state;
    }
};

export const UserProvider = ({children}: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, userState);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
};

export const useUserState = () => {
    const state = useContext(UserStateContext);
    if (!state) throw new Error('No User State');
    return state;
};

export const useUserDispatch = () => {
    const dispatch = useContext(UserDispatchContext);
    if (!dispatch) throw new Error('No User Dispatch');
    return dispatch;
};

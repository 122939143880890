import * as url from "url";

export const GOOGLE_ANALYTICS_ID = `${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`;
export const GOOGLE_ADS_CONVERSION_ID = 'AW-1000243083';
export const GOOGLE_EVENT_CONVERSION_ID = 'AW-944994218';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageView = (url: URL) => {
  window.gtag("config", GOOGLE_ADS_CONVERSION_ID, { page_path: url });
};

type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value
  });
};


export const ads = {
  conversionActions: {
    // Event snippet for 장바구니 담기 conversion page 구글 애즈
    // function gtag_report_conversion(url){
    //   var callback = function(){
    // }
    addToCartPage: (value: number, currency: string = 'KRW') => {
      const actionCode = 'X0ZZCJLAvpIYEKrvzcID';
      window.gtag('event', 'conversion', {
        'send_to': `${GOOGLE_EVENT_CONVERSION_ID}/${actionCode}`,
        'value': value,
        'currency': currency,
        // 'event_callback': callback
      });
    },

    // Event snippet for 회원가입 conversion page 구글 애즈
    signUp: () => {
      const actionCode = 'FSAtCJXAvpIYEKrvzcID';
      window.gtag('event', 'conversion', {
        'send_to': `${GOOGLE_EVENT_CONVERSION_ID}/${actionCode}`,
        'value': 1.0,
        'currency': 'KRW',
      });
    },

    // Event snippet for 구매 conversion page 구글 애즈
    paymentSuccess: (value: number, currency: string = 'KRW') => {
      const actionCode = 'yzPnCN_RnXoQqu_NwgM';
      window.gtag('event', 'conversion', {
        'send_to' : `${GOOGLE_EVENT_CONVERSION_ID}/${actionCode}`,
        'value': value,
        'currency' : currency,
        'transaction_id': ''
      });
    },

    // Event snippet for 외부 클릭 (new) conversion page
    enter: () => {
      const actionCode = 'gVivCKv9yZ8DEIv_-dwD';
      window.gtag('event', 'conversion', {
        'send_to': `${GOOGLE_ADS_CONVERSION_ID}/${actionCode}`,
        'value': 1.0,
        'currency': 'KRW'
      });
    },

    // Event snippet for 페이지 조회 (new) conversion page
    pageView: (url?: URL) => {
      const actionCode = 'icW8CMvJgKADEIv_-dwD';
      window.gtag('event', 'conversion', {
        'send_to': `${GOOGLE_ADS_CONVERSION_ID}/${actionCode}`,
        'value': 1.0,
        'currency': 'KRW',
      });
    },

    // Event snippet for 가입 (new) conversion page
    join: () => {
      const actionCode = 'nFkACOTHgKADEIv_-dwD';
      window.gtag('event', 'conversion', {
        'send_to': `${GOOGLE_ADS_CONVERSION_ID}/${actionCode}`,
        'value': 1.0,
        'currency': 'KRW',
      });
    },

    // Event snippet for 장바구니에 추가 (new) conversion page
    addToCart: (value: number, currency: string = 'KRW') => {
      const actionCode = 'VVI6CN64gKADEIv_-dwD';
      window.gtag('event', 'conversion', {
        'send_to': `${GOOGLE_ADS_CONVERSION_ID}/${actionCode}`,
        'value': value,
        'currency': currency,
      });
    },

    // Event snippet for 구매 (new) conversion page
    purchase: (value: number, currency: string = 'KRW') => {
      const actionCode = 'tIebCIDhyZ8DEIv_-dwD';
      window.gtag('event', 'conversion', {
        'send_to': `${GOOGLE_ADS_CONVERSION_ID}/${actionCode}`,
        'value': value,
        'currency': currency,
        'transaction_id': ''
      });
    },
  }
};

const gtag = {
  GOOGLE_ADS_CONVERSION_ID,
  GOOGLE_EVENT_CONVERSION_ID,
  pageView,
  event,
  ads,
}

export default gtag;

import {useEffect, useState} from "react";
import {getNotiCategories} from "../../apis/notification";
import {NotiCategoryDto} from "../../types/notification";
import qs from "qs";
import {useHistory} from "react-router-dom";
import {useUserState} from "../../context/UserContext";

type NotificationTabsProps = {
    resetKey: number;
};

const NotificationTabs = (props: NotificationTabsProps) => {
    const history = useHistory();
    const query = qs.parse(history.location.search, {ignoreQueryPrefix: true});
    const tabId = query.tab;
    const [categories, setCategories] = useState<NotiCategoryDto[]>([]);
    const {isLoggedIn} = useUserState();
    const authToken = localStorage.getItem('authToken');

    const onClickCategory = (category: NotiCategoryDto) => {
        history.replace({search: `?tab=${category.id}`});
        window.scrollTo(0, 0);
    }

    const getCategories = () => {
        getNotiCategories().then((res) => {
            setCategories(res.data.payload);
            if (authToken || isLoggedIn) {
                tabId ? history.replace({search: `?tab=${tabId}`}) : history.replace({search: undefined});
            } else {
                const noticeId = res.data.payload.find((category) => category.name === '공지')?.id;
                history.replace({search: `?tab=${noticeId}`});
            }
        });
    }

    useEffect(() => {
        getCategories();
    }, [props.resetKey]);

    useEffect(() => {
        if (isLoggedIn === undefined) return;
        getCategories();
    }, [isLoggedIn]);

    return (
        <div className={'flex justify-center border-b md:border-b-0 p-4 -mx-4 no-scrollbar'}>
            {isLoggedIn && (
                <button onClick={() => history.replace({search: undefined})}>
                    <div
                        className={`px-4 py-1.5 font-medium text-12 border-b-2 ${!tabId ? 'border-black' : 'border-transparent'}`}>
                        전체
                    </div>
                </button>
            )}
            {categories.map((category) => {
                if (category.id === 9 || category.id === 10) return null;
                if (!isLoggedIn && category.name !== '공지') return null;
                return (
                    <button key={category.id} onClick={() => onClickCategory(category)}>
                        <div
                            className={`relative px-4 py-1.5 font-medium text-12 border-b-2 ${category.id.toString() === tabId ? 'border-black' : 'border-transparent'}`}>
                            {category.name}
                            {(category.news || 0) > 0 && (
                                <span className="w-2 h-2 bg-red-500 absolute top-0.5 border border-white rounded-2xl"/>
                            )}
                        </div>
                    </button>
                );
            })}
        </div>
    )
}

export default NotificationTabs;

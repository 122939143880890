import React from 'react';
import KAKAO_PIXEL_ID from '../constants/kakao';
import {TOrderDetail} from '../types/types';
import debug from '../utils/logging.js';

const log = (name: string, ...args: any[]) => debug.log('[Kakao Pixel]', `(${name})`, "\t", ...args);

const KakaoPixel = {
  // 방문
  PageView: (): void => {
    log('pageView');
    const script = document.createElement('script');
    script.innerHTML = `
      kakaoPixel('${KAKAO_PIXEL_ID}').pageView();
    `;
    document.body.appendChild(script);
    document.body.removeChild(script);
  },

  // 콘텐츠 보기
  ViewContent: (product_id?: number): void => {
    if (product_id === undefined ) {
      return;
    }
    log('viewContent', product_id);
    var prod_dict= { "id" : product_id };
    const script = document.createElement('script');
    script.innerHTML = `
      kakaoPixel('${KAKAO_PIXEL_ID}').viewContent(${JSON.stringify(prod_dict)});
    `;
    document.body.appendChild(script);
    document.body.removeChild(script);
  },
  
  // 장바구니 추가
  AddToCart: (product_id?: number) => {
    if (product_id === undefined ) {
      return;
    }
    log('AddToCart', product_id);
    var prod_dict= { "id" : product_id };
    const script = document.createElement('script');
    script.innerHTML = `
      kakaoPixel('${KAKAO_PIXEL_ID}').addToCart(${JSON.stringify(prod_dict)});
    `;
    document.body.appendChild(script);
    document.body.removeChild(script);
  },

  // 장바구니 보기
  ViewCart: (): void => {
    log("ViewCart");
    const script = document.createElement('script');
    script.innerHTML = `
      kakaoPixel('${KAKAO_PIXEL_ID}').viewCart();
    `;
    document.body.appendChild(script);
    document.body.removeChild(script); 
  },

  // 구매
  Purchase: (data : TOrderDetail) => {
    if (data === undefined) {
      return;
    }
    let products :any[] = [];
    for (let prod of data.products) {
      var item = {'id': prod.productId, 'name': prod.productName, 'quantity': prod.count, 'price': prod.unitPrice };
      products.push(item);
    }
    var purchase_dict  = {
      'total_quantity': data.products.length, 
      'total_price': data.totalPrice, 
      'currency': "KRW",
      "products" : products,
    };
    const script = document.createElement('script');
    log("purchase", JSON.stringify(purchase_dict));
    script.innerHTML = `
      kakaoPixel('${KAKAO_PIXEL_ID}').purchase(${JSON.stringify(purchase_dict)});
    `; 
    document.body.appendChild(script);
    document.body.removeChild(script);
  },

  // 회원가입 
  CompleteRegistration: () => {
    log("CompleteRegistration");
    const script = document.createElement('script');
    script.innerHTML = `
      kakaoPixel('${KAKAO_PIXEL_ID}').completeRegistration();
    `;
    document.body.appendChild(script);
    document.body.removeChild(script); 
  },

  // 관심상품 추가
  AddToWishlist: (product_id?: number) => { 
    if (product_id === undefined ) {
      return;
    }
    log("AddToWishlist", product_id);
    var prod_dict= { "id" : product_id };
    const script = document.createElement('script');
    script.innerHTML = `
      kakaoPixel('${KAKAO_PIXEL_ID}').addToWishList(${JSON.stringify(prod_dict)});
    `;
    document.body.appendChild(script);
    document.body.removeChild(script); 
  },

  // 잠재고객
  Participation: () => {
    log("Participation");
    const script = document.createElement('script');
    script.innerHTML = `
      kakaoPixel('${KAKAO_PIXEL_ID}').participation();
    `;
    document.body.appendChild(script);
    document.body.removeChild(script); 
  },
};


export default KakaoPixel;


import React from "react";
import { Icon } from "../Icon";

type NotificationTitleProps = {
    title: string;
    showIcon?: boolean;
}

const NotificationTitle = (props: NotificationTitleProps) => {
    return (
        <div className={'flex justify-between items-center p-4 border-b md:border-b-0 -mx-4'}>
            {props.showIcon && (
                <div className={'w-6 md:hidden'}/>
            )}
            <div className={'flex-1 text-center font-bold text-15'}>
                {props.title}
            </div>
            {props.showIcon && (
                <div className={'w-6 items-center justify-center md:hidden'}>
                    <a href={'/notification/settings'} className={'block'}>
                        <Icon.Settings className={'w-5 h-5'}/>
                    </a>
                </div>
            )}
        </div>
    )
}

export default NotificationTitle;
